<template>
  <vuestic-widget :loading="loading" class="automation-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Add New User</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver 
      v-slot="{ handleSubmit, invalid, dirty }"
      ref="userCreateForm"
    >
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="userCreateForm narrow-form1">
        <div class="row">
          <div class="col-md-12">
            <text-input name="First Name" v-model="formData.first_name" label="First Name" validate="required" />
          </div>
          <div class="col-md-12">
            <text-input name="Last Name" v-model="formData.last_name" label="Last Name" validate="required" />
          </div>
          <div class="col-md-6">
            <phone-number-input name="Phone" label="Phone" v-model="formData.phone" :required="true"/>
          </div>
          <div class="col-md-6">
            <text-input type="email" name="email" placeholder="Enter email" v-model="formData.email" label="Email" validate="required|email"/>
          </div>
        </div>

        <div class="row mt-1">
          <div class="col-md-12 mt-3 mb-4">
            <h5 class="text-primary font-weight-bold">User Type</h5>
          </div>
          <div class="col-md-12">
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="radio" name="schedule" id="accountManager" value="1" v-model="formData.user_type">
              <label for="accountManager">
                <span class="abc-label-text">Account Manager <a v-b-tooltip.hover title="Account Manager has access to Businesses, International, Plans, Zapier, Settings and Training pages" class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="radio" name="schedule" id="financialUser" value="2" v-model="formData.user_type">
              <label for="financialUser">
                <span class="abc-label-text">Financial User <a v-b-tooltip.hover title="Financial User has access to Businesses, Plans, Zapier and Settings pages" class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="radio" name="schedule" id="marketingSupport" value="3" v-model="formData.user_type">
              <label for="marketingSupport">
                <span class="abc-label-text">Marketing Support <a v-b-tooltip.hover title="Marketing Support has access to Businesses, Widget Landing and Zapier pages" class="fa fa-question-circle tooltip-icon" /></span>
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="control-label my-2">Send Registration Email</div>
            <vuestic-switch v-model="formData.send_email" :offcolor=true>
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <router-link class="btn btn-primary btn-danger mr-2" :to="{name: 'agency.users.index'}">
              <span>Back</span>
            </router-link>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
              <span v-else>Create</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
  import { mapState } from 'vuex';
  import TimeSelector from "../../forms/TimeSelector";

  export default {
    components: { 
      TimeSelector,
    },
    data() {
      return {
        loading: false,
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          send_email: true,
          user_type: "1",
        }
      };
    },

    created () {
    },

    computed: {
      ...mapState('agencyUser', {
        fetching: 'fetching',
        didFetch: 'didFetch',
        processing: 'processing',
      }),
    },
    mounted() {

    },
    methods: {
      onFormSubmit() {
        const param = {
          ...this.formData,
        }
        this.$store
          .dispatch('agencyUser/create', param)
          .then(() => {
            this.$store.dispatch("auth/getProfile");
            this.$router.push({ name: 'agency.users.index' });
          })
          .catch((err) => {
          })
      },
    },

  };
</script>

<style lang="scss" scoped>
  .userCreateForm {
    max-width: 768px;
  }
  .vuestic-switch {
    max-width: 200px;
    width: 200px;
  }
  .checkbox-label {
    --primaryColor: #3578c6;
  }
  .custom-checkbox-wrapper .checkbox-label .checkbox-custom {
    border-color: $text-gray;
    top: 4px;
    height: 17px;
    width: 17px;
  }

  .custom-checkbox-wrapper .checkbox-label input:checked ~ .checkbox-custom::after {
    left: 5px;
    top: 0px;
  }
</style>